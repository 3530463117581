<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="message_box mt-3">
				<span class="txt_body1">
					<div v-html="msg"></div>
				</span>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	props: {},
	mounted() {
		this.msg = this.$route.params.msg
	},
	data() {
		return {
			msg: ''
		}
	},
	methods: {}
}
</script>

<style scoped lang="scss"></style>
